@import url(./global.css);
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.main-container{
  background: var(--body-bg);
}

.sidebar-container
{
  
  /* max-width: 250px; */
  color:var(--white);
  transition: all 0.3s;
}

.sidebar-container{
  .sidebar-header{
    min-height: 160px;
    display: flex;
    align-items: center;
    justify-content: start;
    }
  .navigation-container{
    svg{
       font-size: 1.2rem;
       margin-right: 2rem;
     }
   }
   .nav-item{
    .nav-link{
      margin: 5px 10px;
     
      &:hover{
        background: rgba(255, 255, 255, 0.2);
        border-radius: 6px;
      }
     
    }
    .active{
      background: rgba(255, 255, 255, 0.2);
      border-radius: 6px;
    }
    .nav{
      .nav-link{
        margin: 5px 30px;
      }
    }
   }

}


.sidebar-container.open{
  width: 290px;
  h3{
    margin: 1.5rem 0;
    .brand_logo{
      max-width: 110px;
      margin: 0 auto;
    }
  }
  
}

.sidebar-container.collapsee{
  min-width: 150px;
  // margin-left: -150px;
  transition: all 0.3s;
  .nav-label{
    display: none;
  }
  .brand_logo{
    max-width: 60px;
    margin: 0 auto;
    transition: all 0.3s;
  }
  .nav-item{
    .nav-link{
      justify-content: center;
    }
  }
}
.content {
 
  /* padding: 20px; */
  min-height: 100vh;
  transition: all 0.3s;
 
}

.sidebar-container.open + .content {
  width: calc(100% - 290px);
}

.sidebar-container.collapsee+ .content {
  width: calc(100% - 150px);
}

.content-inner{
  min-height: 100%;
  height: 100vh;
  overflow-y: scroll;
}



.sidebar-container.open {
  transition: all 0.3s;
}

.sidebar-container.collapsee {
  // margin-left: -150px;
  transition: all 0.3s;
}

#sidebar{

  background: var(--primary-clr1);
  min-height: 100vh;
  .nav-item{
    .nav-link{
      color:var(--white)
    }
  }

  .sub-menu-main{
    position: relative;
    display: flex;
    width:auto;
    &::after{
    display: block;
    flex: 0 12px;
    height: 18px;
    content: "";
    
    background-repeat: no-repeat;
    background-position: center;
    transition: transform .15s;
    color: var(--white);
    font-size: 24px;
    position: absolute;
    right:5px;
    top:50%;
    transform: translate(-50%, -50%);
    width:18px;
    }
  }
}

.sub-menu-main.close{
  &::after{
    background-image:url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1.5em' height='1.5em' viewBox='0 0 24 24'%3E%3Cpath fill='white' d='M12 14.708L6.692 9.4l.708-.708l4.6 4.6l4.6-4.6l.708.708z'/%3E%3C/svg%3E");
  }
}

.sub-menu-main.open{
  &::after{
    background-image:url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1.5em' height='1.5em' viewBox='0 0 24 24'%3E%3Cpath fill='none' stroke='white' stroke-linecap='round' stroke-linejoin='round' d='m17 14l-5-5l-5 5'/%3E%3C/svg%3E");
  }
}


.outlet-container{
  height: calc(100vh - 70px);
  padding: 15px;
  overflow-y: scroll;
  
}

.outlet-inner{
  background: var(--white);
  border-radius: 5px;
  width:auto;
  // min-height: calc(100vh - 180px);
  // overflow-y: auto;
}

.static-info-row{
  .custom-card{
    background: #fff;
    color:#003263;
    border:solid 1px rgba(0, 50, 99, 0.5);
    border-radius: 5px;
    .info-icon{
      background: rgba(0, 50, 99, 0.2);
      min-width:2rem;
      height: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
    }
  }
}

.graph_bg{
  background: #fff;
  border:solid 1px rgba(0, 50, 99, 0.5);
    border-radius: 5px;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-left:auto;
    margin-right: auto;
    width: 100%;
}


.static-info-row.inner-cards{
  .custom-card{
    min-height: 145px;
    h4{
      font-size: 1.4rem;
      font-weight: 500;
    }
    h3{
      font-size: 1.8rem;
      font-weight: 700;
    }
  }
}

.dashboard.static-info-row h3{
  font-size: 1.4rem;
}

.dashboard.static-info-row h5{
  font-size: 1.8rem;
  font-weight: 700;
}

.TAB_container .custom-card{
  background: #e3ecf5 !important;
    border: 1px solid #00326380 !important;
}
// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) { 
  

 }

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) { 
  .sidebar-container{
    position: absolute;
    // z-index: 99;
    top: 0;
    left: 0;
    // width: 250px; 
    // height: 100%;
    // background-color: #fff;
    z-index: 1000;
    // transition: transform 0.3s ease;
    // transform: translateX(-100%);
  }

  .backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
    z-index: 999; /* Ensure it's above everything else */
  }

  .sidebar-container.collapsee{
    left:0px;
  }
  
  .content{
    width:100%;
  }

  .sidebar-container.open + .content{
    width:100%;
  }

  .sidebar-container.collapsee + .content {
    width:100%;
  }

  .sidebar-container.open{
    // display: none;
  }
 }

 .top-nav-container {
  background-color: #ffffff; /* Background color */
  border-bottom: 1px solid #e1e1e1; /* Bottom border */
  padding: 10px; /* Padding around the Navbar */
}

.navbar-brand {
  font-size: 1.5rem; /* Font size for brand text */
  font-weight: bold; /* Font weight for brand text */
}

.navbar-nav {
  display: flex;
  align-items: center;
}

.nav-link {
  color: #000000; /* Link color */
  margin-right: 10px; /* Right margin between links */
}

.nav-link:hover {
  text-decoration: none; /* Remove underline on hover */
}

.profile-image {
  width: 30px; /* Width of profile image */
  height: 30px; /* Height of profile image */
  border-radius: 50%; /* Rounded corners for circular shape */
  margin-right: 10px; /* Right margin */
}

.profile-main {
  margin-right: 20px; /* Margin between profile dropdown and other links */
}

.dropdown-menu {
  min-width: auto; /* Minimum width of dropdown menu */
}

.dropdown-item {
  font-size: 14px; /* Font size of dropdown items */
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) { 
  
}

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) { 
  
 }

// X-Large devices (large desktops, less than 1400px)
@media (max-width: 1399.98px) {
  
 }
