.top-nav-container{
    .profile_main{
        .dropdown-menu{
            right:0;
            left: auto !important;
            position: absolute !important;
        }
    }
}


