:root {
    --white: #ffffff;
    --primary-clr1: #003263;
    --primary-clr2: #bf81f5;
    --primary-clr3: #e9d0ff;
    --secondory-clr1: #07d4c6;
    --secondory-clr2: #07d4c6;
    --secondory-clr3: #07d4c6;
    --background-dark1: #11111b;
    --background-dark2: #11111b;
    --text-grey1:#C5C5C5;
    --text-white:#ffffff;
    --font-16:.9rem;
    --font-16b:1rem;
    --font-18:1.2rem;
    --fwt-600:600;
    --bg-footer:#07070B;
    --font-dark: #131313;
    --profile-menu-bg: #1D1E28;
    --background-grey:#1D1D27;
    --teal-10:rgba(7, 212, 198, 0.1);
    --wxlf-red:rgba(255, 91, 91, 1);
    --body-bg:#E3ECF5;
  }
  
