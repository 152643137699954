/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
 

@tailwind base;
@tailwind components;
@tailwind utilities;


.val_primary_btn{
  background: var(--primary-clr1) !important;
  color:var(--text-white) !important;
  border:none;
}

.Trasparent_primary_btn{
  background: transparent !important;
  color:var(--text-white) !important;
  border:none !important;
}

/* // X-Small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) { 
  
 }

/* // Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) { 
  .Trasparent_primary_btn{
    background: transparent !important;
    color:var(--text-white) !important;
    border:none !important;
  }
 }

/* // Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) { 
  
 }

/* // Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) { 
  
 }

/* // X-Large devices (large desktops, less than 1400px) */
@media (max-width: 1399.98px) { 
  
 }

/* // XX-Large devices (larger desktops) */